.workflow {
    display: flex;
    flex-direction: column;
    gap: 30px;
  }
  
  .workflow-section {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }
  
  .section-title {
    color: #333;
    font-size: 16px;
    margin-bottom: 10px;
    font-weight: bold;
  }
  
  .workflow-steps {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
  }
  
  .workflow-step {
    position: relative;
    background-color: #eee;
    color: #000;
    padding: 10px 1rem 10px 2rem;
    font-size: 14px;
    white-space: nowrap;
  }
  
  .workflow-step::before {
    content: "";
    position: absolute;
    border-top: 22px solid transparent;
    border-bottom: 22px solid transparent;
    border-left: 22px solid #fff;
    left: -3px;
    top: 0px;
}

  .workflow-step::after {
    content: "";
    position: absolute;
    border-top: 22px solid transparent;
    border-bottom: 22px solid transparent;
    border-left: 22px solid #eee;
    right: -21px;
    top: 0px;
    z-index: 1;
  }
  
.list-step-numbers {
    counter-reset: step;
    list-style-type: none;
    margin-left: 0px;
}

.list-step-numbers li {
    position: relative;
    padding: 0 0 1.5rem 2.5rem;
    min-height: 75px;
}

.list-step-numbers li::before {
    counter-increment: step;
    content: counters(step, ".") " ";
    position: absolute;
    left: -20px;
    top: 0;
    height: 35px;
    width: 35px;
    font-size: 17px;
    font-weight: 700;
    line-height: 34px;
    text-align: center;
    color: #fff !important;
    background-color: #0093dd;
    border: 2px solid #0194de;
    border-radius: 50%;
    z-index: 2;
}